import React, {useEffect, useState} from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    useParams
} from "react-router-dom";


const columns = [
  { id: 'level', label: 'Уровень образования', minWidth: 100 },
  {
    id: 'code',
    label: 'Код специальности, направления подготовки',
    minWidth: 220,
  },
  {
    id: 'name',
    label: 'Наименование образовательной программы (профиль)',
    minWidth: 400,
  },
  {
    id: 'dolgnosti',
    label: 'Возможные должности в образовательных организациях',
    minWidth: 420,
    align: 'center',
  },
  {
    id: 'vipusk2023',
    label: '2023',
    align: 'center',
  },
  {
    id: 'vipusk2024',
    label: '2024',
    align: 'center',
  },
  {
    id: 'vipusk2025',
    label: '2025',
    align: 'center',
  },
  {
    id: 'vipusk2026',
    label: '2026',
    align: 'center',
  },
  {
    id: 'vipusk2027',
    label: '2027',
    align: 'center',
  },
  {
    id: 'vipusk2028',
    label: '2028',
    align: 'center',
  },
  {
    id: 'data:0',
    label: 'ОО',
  },
  {
    id: 'data:1',
    label: 'ОЗО',
  },
  {
    id: 'data:2',
    label: 'ЗО',
  },
  {
    id: 'sum:1',
    label: 'Всего',
  },
  {
    id: 'data:3',
    label: 'ОО',
  },
  {
    id: 'data:4',
    label: 'ОЗО',
  },
  {
    id: 'data:5',
    label: 'ЗО',
  },
  {
    id: 'sum:2',
    label: 'Всего',
  },
  {
    id: 'data:6',
    label: 'ОО',
  },
  {
    id: 'data:7',
    label: 'ОЗО',
  },
  {
    id: 'data:8',
    label: 'ЗО',
  },
  {
    id: 'sum:3',
    label: 'Всего',
  },
  {
    id: 'data:9',
    label: 'ОО',
  },
  {
    id: 'data:10',
    label: 'ОЗО',
  },
  {
    id: 'data:11',
    label: 'ЗО',
  },
  {
    id: 'sum:4',
    label: 'Всего',
  },
  {
    id: 'data:12',
    label: 'ОО',
  },
  {
    id: 'data:13',
    label: 'ОЗО',
  },
  {
    id: 'data:14',
    label: 'ЗО',
  },
  {
    id: 'sum:5',
    label: 'Всего',
  },
  {
    id: 'data:15',
    label: 'ОО',
  },
  {
    id: 'data:16',
    label: 'ОЗО',
  },
  {
    id: 'data:17',
    label: 'ЗО',
  },
  {
    id: 'sum:6',
    label: 'Всего',
  },
];

const levels = [
  "",
"Среднее профессиональное образование",
"Высшее образование - бакалавриат",
"Высшее образование - специалитет",
];

const dolgnosti = [
"",
"Учитель истории, экономики, права, обществознания",
"Учитель начальных классов",
"Учитель русского языка и литературы",
"Учитель языка народов России и литературы (кроме русского языка)",
"Учитель информатики и ИКТ",
"Учитель физики",
"Учитель математики",
"Учитель химии",
"Учитель географии",
"Учитель биологии",
"Учитель иностранных языков (английского языка)",
"Учитель иностранных языков (немецкого языка)",
"Учитель иностранных языков (французского языка)",
"Учитель иностранных языков (другие)",
"Учитель физической культуры",
"Учитель трудового обучения (технологии)",
"Учитель музыки и пения",
"Учитель изобразительного искусства, черчения",
"Учитель основ безопасности жизнедеятельности",
"Учитель прочих предметов",
"Учитель-логопед",
"Учитель-дефектолог (олигофренопедагог)",
"Учитель-дефектолог (тифлопедагог)",
"Учитель-дефектолог (сурдопедагог)",
"Социальный педагог",
"Педагог дополнительного образования",
"Педагог-психолог",
"Воспитатель",
"Мастер производственного обучения",
"Тьютор",
"Другие",
];

const codes = {
  "Высшее образование - специалитет": [
    "44.05.01 Педагогика и психология девиантного поведения",
  ],
  "Высшее образование - бакалавриат": [
    "44.03.01. Педагогическое образование",
    "44.03.02. Психолого-педагогическое образование",
    "44.03.03. Специальное (дефектологическое) образование",
    "44.03.04. Профессиональное обучение (по отраслям)",
    "44.03.05. Педагогическое образование (с двумя профилями подготовки)",
  ],
  "Среднее профессиональное образование": [
    "44.02.01 Дошкольное образование",
    "44.02.02 Преподавание в начальных классах",
    "44.02.03 Педагогика дополнительного образования",
    "44.02.04 Специальное дошкольное образование",
    "44.02.05 Коррекционная педагогика в начальном образовании",
    "44.02.06 Профессиональное обучение (по отраслям)",
  ]
};

export default function Anketa(props) {
  useEffect(() => {
    getList();
  }, []);

  let params = useParams();

  const getList = async () => {
    try {
      let res = await axios.get(`/json/forms/${props.admin ? 'getAnketaListAdmin' : 'getAnketaList'}?rnd=${Math.random()}${props.admin ? '&username=' + params.id : ''}`);
      if (!!res.data.status && res.data.status === "ok") {
        setList(res.data.list.concat([{
          _id: "",
          level: levels[0],
          name: "",
          dolgnosti: [dolgnosti[0]],
          data: Array(18).fill(0)
        }]));
        console.log(list);
      } else {
        console.log("error")
        props.showSnackbar("Ошибка запроса списка", "error");
      }
    } catch (e) {
      console.log(e)
    }
  }

  const removeAnketa = async (id) => {
    try {
      let res = await axios.get(`/json/forms/removeAnketa?id=${id}`);
      if (!!res.data.status && res.data.status === "ok") {
        getList();
      } else {
        console.log("error")
        props.showSnackbar("Ошибка удаления", "error");
      }
    }catch(e){
      console.log(e);
    }
  }

  const handleChange = async (ev) => {
    try {
      const field = ev.target.name.split('-')[0];
      const id = ev.target.name.split('-')[1];
      let value = ev.target.value;
      if (field === "dolgnosti") {
        let newlist = [...list];
        value = value.filter(el => el !== "");
        console.log(value);
        newlist[newlist.findIndex((el) => el._id === id)].dolgnosti = value;
        setList(newlist);
        value = value.join(';');
      }
      let res = await axios.get(`/json/forms/${id === "" ? `addAnketa?` : `saveAnketa?id=${id}&`}field=${field}&value=${value}`);
      if (!!res.data.status && res.data.status === "ok") {
        props.showSnackbar(id === "" ? "Данные добавленны" : "Данные обновлены", "success");
        getList()
      } else {
        console.log("error")
        props.showSnackbar("Ошибка доступа", "error");
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [list, setList] = useState([]);

  return (
    <TableContainer sx={{ width: '100%', overflowX: 'unset' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead >
          <TableRow>
            {columns.filter((clmn) => !/\d+/.test(clmn.id)).map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, fontSize: '1.2rem', fontWidth: 'bold', borderRightStyle: /vipusk\d+/.test(column.id) ? "solid" : "none", borderRightColor: "rgb(224, 224, 224)" }}
                colSpan={['level', 'name', 'dolgnosti', 'code'].includes(column.id) ? 1 : /vipusk\d+/.test(column.id) ? 4 : 2}
                rowSpan={['level', 'name', 'dolgnosti', 'code'].includes(column.id) ? 3 : 1}
              >
                {column.label}
              </TableCell>
            ))}
            <TableCell
              style={{ fontSize: '1.2rem', fontWidth: 'bold' }}
              colSpan={24}
              align={'center'}
            >
              Ожидаемый выпуск
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.filter((clmn) => /vipusk\d+/.test(clmn.id)).map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, fontSize: '1.2rem', fontWidth: 'bold', borderRightStyle: /vipusk\d+/.test(column.id) ? "solid" : "none", borderRightColor: "rgb(224, 224, 224)" }}
                colSpan={['level', 'name', 'dolgnosti', 'code'].includes(column.id) ? 1 : /vipusk\d+/.test(column.id) ? 4 : 2}
                rowSpan={['level', 'name', 'dolgnosti', 'code'].includes(column.id) ? 2 : 1}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.filter((clmn) => /dolgnosti:\d+/.test(clmn.id)).map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
            {columns.filter((clmn) => /data:\d+/.test(clmn.id) || /sum:\d+/.test(clmn.id)).map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: 100, fontWidth: 'bold', borderRightStyle: /sum:\d+/.test(column.id) ? "solid" : "none", borderRightColor: "rgb(224, 224, 224)" }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.map((p, idx) => (
              <TableRow key={idx}>
                {
                  columns.filter(c => c.id && !/vipusk\d+/.test(c.id)).map((clmn, idx) => (
                    <TableCell key={'c' + idx} style={{ borderRightStyle: /sum:\d+/.test(clmn.id) ? "solid" : "none", borderRightColor: "rgb(224, 224, 224)" }}>
                      {clmn.id === 'level' ?
                        <Select
                          id={`level-${p._id}`}
                          name={`level-${p._id}`}
                          value={p.level}
                          onChange={handleChange}
                          fullWidth
                        >
                          {levels.map(l => (
                            <MenuItem key={l} value={l}>{l}</MenuItem>
                          ))}
                        </Select>
                        : clmn.id === 'code' ?
                          <Select
                            id={`code-${p._id}`}
                            name={`code-${p._id}`}
                            value={p.code}
                            onChange={handleChange}
                            fullWidth
                            disabled={!!!p.level || !!!codes[p.level]}
                          >
                            {!!p.level && !!codes[p.level] && codes[p.level].map(l => (
                              <MenuItem key={l} value={l}>{l}</MenuItem>
                            ))}
                          </Select>
                          : clmn.id === 'name' ?
                            <TextField
                              id={`${clmn.id}-${p._id}`}
                              name={`${clmn.id}-${p._id}`}
                              value={p[clmn.id]}
                              onBlur={handleChange}
                              fullWidth
                              onChange={(ev) => {
                                let newlist = [...list];
                                newlist[list.findIndex(e => e._id === p._id)][clmn.id] = ev.target.value;
                                setList(newlist);
                              }}
                            />
                            : /dolgnosti/.test(clmn.id) ?
                              <Select
                                id={`${clmn.id}-${p._id}`}
                                name={`${clmn.id}-${p._id}`}
                                value={p.dolgnosti}
                                onChange={handleChange}
                                multiple
                                MenuProps={{
                                  sx: {
                                    "&& .Mui-selected": {
                                      backgroundColor: "green"
                                    }
                                  }
                                }}
                              >
                                {dolgnosti.map(l => (
                                  <MenuItem key={l} value={l}>{l}</MenuItem>
                                ))}
                              </Select>
                              : /data/.test(clmn.id) ?
                                <TextField
                                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                  id={`${clmn.id}-${p._id}`}
                                  name={`${clmn.id}-${p._id}`}
                                  value={p[clmn.id.split(':')[0]][clmn.id.split(':')[1]]}
                                  onBlur={handleChange}
                                  onChange={(ev) => {
                                    let newlist = [...list];
                                    newlist[list.findIndex(e => e._id === p._id)][clmn.id.split(':')[0]][clmn.id.split(':')[1]] = Number.isInteger(parseInt(ev.target.value, 10)) ? parseInt(ev.target.value, 10) : 0;
                                    setList(newlist);
                                  }}
                                />
                                :
                                <b>{p.data.slice((clmn.id.split(':')[1] - 1) * 3, (clmn.id.split(':')[1]) * 3).reduce((sum, a) => sum + a, 0)}</b>
                      }
                    </TableCell>
                  ))
                }
                {!!p._id &&
              <TableCell>
                  <IconButton aria-label={"Удалить"} color={"error"} onClick={() => { removeAnketa(p._id) }}>
                    <DeleteIcon />
                  </IconButton>
              </TableCell>
}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

