import './App.css';
import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Toolbar from '@mui/material/Toolbar';
import {
    Link, Route, Routes,
    Navigate, useNavigate
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';


import NewPassword from './NewPassword.js';
import SignUp from "./SignUp";
import Login from "./Login.js";
import Dashboard from "./Dashboard";
import AdminDashboard from "./AdminDashboard";
import Forms from "./Forms";
import MailTemplatesList from "./MailTemplatesList";
import MailTemplate from "./MailTemplate";
import UserInfoForm from "./UserInfoForm";
import Anketa from "./Anketa";
import Error401 from "./Error401";
import Error404 from "./Error404";
import RequestPasswordReset from './RequestPasswordReset.js';


const theme= createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#355264',
        },
        secondary: {
            main: '#e4541d',
        },
    },
});


function App() {

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
  };

  let navigate = useNavigate();

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("");
  const [userRole, setUserRole] = React.useState("");
  const [isLoginIn, setIsLoginIn] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const showSnackbar = (message, severity = "") => {
    setSnackMessage(message);
    setSnackSeverity(severity)
    setSnackOpen(true);
  };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  const getStatus = async () => {
    try {
      let res = await axios.get(`/json/status?rnd=${Math.random()}`);
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        setUserRole(res.data.userRole);
        await new Promise(resolve => setTimeout(resolve, 100));
        setIsLoginIn(true);
      } else {
        setIsLoginIn(false)
        setUserRole("");
      }
    } catch {
      setIsLoginIn(false)
      console.log("error with status request")
    }
  }

  const logout = () => {
    axios.get(`/json/logout`).then((res) => {
      setIsLoginIn(false);
      showSnackbar("Вы вышли из системы")
      navigate("/");
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    getStatus();
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
        <CssBaseline />
        <Box sx={{}}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" sx={{ flexGrow: 1 }} component={Link} to={"/"} style={{ color: "#fff", textDecoration: "none" }}>
                Данные о подготовке педагогических кадров
              </Typography>
              {isLoginIn ?
                <>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => {
                      navigate("/forms/userInfo");
                      handleClose();
                    }}>Профиль</MenuItem>
                    <MenuItem onClick={() => {
                      logout();
                      handleClose();
                    }}>Выйти</MenuItem>
                  </Menu>
                </>
                :
                <Button component={Link} color="inherit" to={"/login"}>Вход</Button>
              }
            </Toolbar>
          </AppBar>
        </Box>
        <Routes>
          <Route path={"/"} element={isLoginIn && userRole === 'valid' ? <Navigate to={`/anketa`} /> :
            isLoginIn && (userRole === 'admin' || userRole === 'viewOnly') ? <Navigate to={`/dashboard/admin`} /> :
              isLoginIn ? <Navigate to={`/noaccess`} /> : <Login showSnackbar={showSnackbar} getStatus={getStatus} />} />
          <Route path={"/signup"} element={<SignUp showSnackbar={showSnackbar} getStatus={getStatus} />} />
          <Route path={"/login"} element={<Login showSnackbar={showSnackbar} getStatus={getStatus} />} />
          <Route path={"/noaccess"} element={<Error401 showSnackbar={showSnackbar} />} />
          <Route path={"requestPasswordReset"} element={<RequestPasswordReset showSnackbar={showSnackbar} />} />
          <Route path={"newPassword/:token"} element={<NewPassword showSnackbar={showSnackbar} />} />
          <Route path={"/dashboard/admin"} element={isLoginIn ? <AdminDashboard showSnackbar={showSnackbar} /> : <Navigate to={`/login`} />} ></Route>
          <Route path={"/dashboard"} element={isLoginIn ? <Dashboard showSnackbar={showSnackbar} /> : <Navigate to={`/login`} />} >
            <Route path={"mailTemplates"} element={<MailTemplatesList showSnackbar={showSnackbar} />} />
          </Route>
          <Route path={"/anketa/:id"} element={isLoginIn ? <Anketa showSnackbar={showSnackbar} admin /> : <Navigate to={`/login`} />} />
          <Route path={"/anketa"} element={isLoginIn ? <Anketa showSnackbar={showSnackbar} /> : <Navigate to={`/login`} />} />
          <Route path={"/forms"} element={isLoginIn ? <Forms /> : <Navigate to={`/login`} />} >
            <Route path={"mailTemplate/:id"} element={<MailTemplate showSnackbar={showSnackbar} edit />} />
            <Route path={"addMailTemplate"} element={<MailTemplate showSnackbar={showSnackbar} />} />
            <Route path={"userInfo"} element={<UserInfoForm showSnackbar={showSnackbar} />} />
            <Route path={"userInfo/:id"} element={<UserInfoForm showSnackbar={showSnackbar} edit />} />
            <Route path={"unsubscribe"} element={<UserInfoForm showSnackbar={showSnackbar} unsubscribe />} />
          </Route>
          <Route
            path={"*"}
            element={<Error404 />}
          />
        </Routes>
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
          {snackSeverity !== "" ?
            <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
              {snackMessage}
            </Alert>
            :
            <p>{snackMessage}</p>
          }
        </Snackbar>
        <Box
          component="footer"
          sx={{
            py: 2,
            px: 2,
            mt: 'auto',
            textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container >
            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12} sm={5}>
                <Typography variant="body1">
                  По вопросам регистрации в информационной системе и заполнения таблицы:  тел. <a href="tel:8(8422)44-30-49">8(8422)44-30-49</a>, электронная почта: <a href="mailto:centr209@ulspu.ru">centr209@ulspu.ru</a>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {'Copyright © '}
                  <MuiLink color="inherit" href={"https://ulspu.ru"}>
                    УлГПУ им. И.Н. Ульянова
                  </MuiLink>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={"/logo192.png"} style={{ height: 80 }} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
