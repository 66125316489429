import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import React from "react";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function Error404() {
    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <ErrorOutlineIcon />
            </Avatar>
            <Alert severity="error">Запрашиваемая Вами страница не найдена :(</Alert>
        </Box>
    );
}
