import React, {useEffect, useState} from "react";
import Table from "./Table";
import IconButton from "@mui/material/IconButton";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
    Link
} from "react-router-dom"; 
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';


import axios from "axios";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function OrganisationsList(props) {
    useEffect(() => {
        getList();
    },[]);

    const [open, setOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const handleOpen = (dt) => {
        console.log(dt);
        setUserInfo({
            fio: dt.fio,
            username: dt.username,
            phone: dt.phone,
            name: dt.name,
        });
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    const handleCloseDel = () => setOpenDel(false);
    const handleOpenDel = (dt) => {
        setUserInfo({
            fio: dt.fio,
            username: dt.username,
            phone: dt.phone,
            name: dt.name,
        });
        setOpenDel(true);
    }
    const [userInfo, setUserInfo] = useState({fio: "", username: "", phone: "", name: ""});


    const getList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/userList?type=valid&rnd=${Math.random()}`);
            if (!!res.data.status && res.data.status === "ok") {
                setOrganisationsList(res.data.rows);
                return res.data.rows;
            } else {
                console.log("error");
                props.showSnackbar("Ошибка запроса списка", "error");
            }
        }catch (e) {
            console.log(e);
            props.showSnackbar("Ошибка запроса списка", "error");
        }
    }

    const userDelete = async () => {
        try {
            let res = await axios.get(`/json/dashboard/userDelete?username=${userInfo.username}`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Организация удалена", "success");
                setOpenDel(false);
                getList();
            } else {
                console.log("error");
                props.showSnackbar("Ошибка удаления", "error");
            }
        }catch (e) {
            console.log(e);
            props.showSnackbar("Ошибка удаления", "error");
        }
    }



    const columns = React.useMemo(
        () => [
            {
                Header: 'Наименование субъекта РФ',
                accessor: 'subject',
            },
            {
                Header: 'Наименование образовательной организации',
                accessor: 'name',
            },
            {
                Header: 'Код ОКОПФ',
                accessor: 'OKOPF',
            },
            {
                Header: 'Принадлежность к государственный, муниципальным или частным образовательным организациям',
                accessor: 'prinadlegnost',
            },
            {
                Header: 'Организация / организации, выполняющая (выполняющии) функции и полномочия учредителя образовательной организации',
                accessor: 'uchereditel',
            },
            {
                Header: 'Образовательных программ',
                accessor: 'anketas',
            },
            {
                Header: 'Управление',
                accessor: 'username',
                disableFilters: true,
                Cell: ({cell}) => (
                    <>
                        <IconButton aria-label={"Удалить"} color={"error"} onClick={() =>{ handleOpenDel(cell.row.original)}}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton aria-label={"Контакт"} color={"primary"} onClick={() =>{ handleOpen(cell.row.original)}}>
                            <InfoIcon />
                        </IconButton>
                        <IconButton aria-label={"Изменить"} color={"warning"} component={Link} to={`/forms/userInfo/${cell.row.values.username}`}  >
                            <EditIcon />
                        </IconButton>
                        <IconButton aria-label={"Данные"} color={"primary"} component={Link} to={`/anketa/${cell.row.values.username}`}  >
                            <ArrowRightAltIcon />
                        </IconButton>
                    </>
                )
            },
        ],
        []
    )

    const [organisations, setOrganisationsList] = useState([]);
    const data = React.useMemo(() => organisations, [organisations])

    return (
        <>
            <Table columns={columns} data={data} />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {userInfo.name}
                    </Typography>
                        <dl>
                            <dt>ФИО</dt>
                            <dd>{userInfo.fio}</dd>
                            <dt>Email</dt>
                            <dd>{userInfo.username}</dd>
                            <dt>Телефон</dt>
                            <dd>{userInfo.phone}</dd>
                        </dl>
                </Box>
            </Modal>
            <Modal
                open={openDel}
                onClose={handleCloseDel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title-del" variant="h6" component="h2">
                        Внимание! Нажав на кнопку ниже Вы удалите организацию и все данные с ней связанные.
                    </Typography>
                    <Typography id="modal-modal-description-del" sx={{ mt: 2 }}>
                        {userInfo.name}
                    </Typography>
                    <Button color="error" onClick={userDelete} >Удалить организацию</Button>
                </Box>
            </Modal>
        </>
    )
}
