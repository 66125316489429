import React, {useEffect, useState} from 'react';
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import MenuItem from '@mui/material/MenuItem'
import EmailIcon from '@mui/icons-material/Email';
import axios from "axios";
import {
    useNavigate, useParams
} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function MailTemplate(props) {

    const handleSubmitTest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console

        axios.post(`/json/forms/sendTestEmail`, {
            email: data.get('email'),
            name: name,
            data: data.get('data'),
        }).then((res) => {
            if(res.data.status === "ok"){
                    props.showSnackbar("Отправлено", "success");
            }else{
                props.showSnackbar(res.data.text, "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        })
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console

        axios.post(`/json/forms/${!!props.edit ? "saveMailTemplate": "addMailTemplate"}`, {
            title: data.get('title'),
            name: name,
            subject: data.get('subject'),
            template: data.get('template'),
        }).then((res) => {
            if(res.data.status === "ok"){
                if(!!props.edit) {
                    props.showSnackbar("Сохранено", "success");
                }else {
                    props.showSnackbar("Добавлено", "success");
                    navigate("/dashboard/mailTemplates");
                }
            }else{
                props.showSnackbar("Ошибка", "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        })
    };

    const [template, setTemplate] = useState("");
    const [title, setTitle] = useState("");
    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);

    let navigate = useNavigate();
    let params = useParams();

    const massSend = async () => {
        try {
            let res = await axios.post(`/json/dashboard/massMessage`,{
                name: params.id
            });
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Массовая рассылка запущенна","success");
            }else{
                props.showSnackbar("Ошибка массовой рассылки", "error");
            }
        }catch(e){
                props.showSnackbar("Ошибка массовой рассылки", "error");
        }
    }

    const loadTemplate = async () => {
        try {
            let res = await axios.get(`/json/forms/mailTemplate?name=${params.id}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setTemplate(res.data.template.template);
                setName(res.data.template.name);
                setSubject(res.data.template.subject);
                setTitle(res.data.template.title);
            } else {
                props.showSnackbar("Ошибка загрузки шаблона", "error");
                console.log("error")
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if(!!props.edit) {
            loadTemplate();
        }
    },[]);

    return (
        <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <EmailIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {!!props.edit ?
                    "Редактировать шаблон" :
                    "Добавить шаблон"
                }
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="title"
                            label="Название шаблона"
                            name="title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="name"
                            label="Системное имя"
                            name="name"
                            disabled={!!props.edit}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="subject"
                            label="Тема письма"
                            name="subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={6}
                            id="template"
                            label="Шаблон письма"
                            name="template"
                            value={template}
                            onChange={(e) => setTemplate(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {!!props.edit ?
                        "Сохранить" :
                        "Добавить"
                    }
                </Button>
            </Box>
            {props.edit &&

            <Box component="form" noValidate onSubmit={handleSubmitTest} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Адресат"
                            name="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                multiline
                                rows={6}
                                id="data"
                                label="Данные JSON"
                                name="data"
                            />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Отправить тестовое письмо
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(e) => {
                        setOpen(true);
                    }}
                >
                    Массовая рассылка
                </Button>
            </Box>
            }
            <Dialog
                open={open}
                onClose={()=>{setOpen(false)}}
                aria-labelledby="Подтверждение рассылки"
                aria-describedby="Подтверждение рассылки"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Вы уверены, что хотите сделать рассылку?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Данное письмо будет отправленно всем пользователям портала.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e)=>{setOpen(false)}}>Нет</Button>
                    <Button onClick={(e)=>{
                        setOpen(false);
                        massSend();
                    }} autoFocus>
                         Отправить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
