import * as React from 'react';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useState} from "react";
import {subjects} from './subjects';

export default function RegionExportCard(props) {

    const [region, setRegion] = useState(subjects[0]);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Выгрузка по регионам
            </Typography>
                            <FormControl fullWidth>
                                <InputLabel id="subject">Наименование субъекта РФ</InputLabel>
                                <Select
                                    id={"subject"}
                                    name={"subject"}
                                    label="Наименование субъекта РФ"
                                    value={region}
                                    onChange={(e)=> setRegion(e.target.value)}
                                >
                                    {subjects.map(l => (
                                        <MenuItem key={l} value={l}>{l}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                    <Button
                        component='a'
                        href={`/json/dashboard/exportRegion?name=${region}`}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        download
                    >
                        Скачать
                    </Button>
        </React.Fragment>
    );
}

