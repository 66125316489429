import React, {useEffect, useState, useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from "@mui/material/Tooltip";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {
    Link as RouterLink,
    useNavigate
} from "react-router-dom";
import axios from 'axios';
import PhoneMask from "./PhoneMask";
import EmailMask from "./EmailMask";
import {subjects} from './subjects';
import {prinadlegnosti} from './prinadlegnosti';




export default function SignUp(props) {


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        let ready = true;
        ['fio', 'email', 'password', 'phone', 'subject', 'name', 'OKOPF', 'prinadlegnost', 'uchereditel'].forEach((fld) => {
            if(data.get(fld)=== ""){
                ready=false;
            }
        });
        if(!ready){
            props.showSnackbar("Вы не заполнили все обязательные поля", "error");
            return;
        }
        try {
            let res = await axios.post(`/json/register`, {
                fio: data.get('fio'),
                username: data.get('email'),
                password: data.get('password'),
                phone: data.get('phone'),
                subject: data.get('subject'),
                name: data.get('name'),
                OKOPF: data.get('OKOPF'),
                prinadlegnost: data.get('prinadlegnost'),
                uchereditel: data.get('uchereditel'),
                token: token,
            });
            if (res.data.status === "ok") {
                props.showSnackbar("Вы успешно зарегистрировались и вошли в систему", "success");
                await props.getStatus();
                navigate("/");
            } else {
                props.showSnackbar("Ошибка регистрации", "error");
            }
        } catch (err) {
            props.showSnackbar("Ошибка регистрации", "error");
            console.log(err);
        }
    };

    const [isAccept, setIsAccept] = useState(false);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    let navigate = useNavigate();
    const [token, setToken] = useState(null);
    const captchaRef = useRef(null);

    useEffect(() => {
        if (token)
            console.log(`hCaptcha Token: ${token}`);
    },[token]);

    const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
        captchaRef.current.execute();
    };

    //TODO: tooltips for 

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Регистрация
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                fullWidth
                                id="fio"
                                label="ФИО контактного лица"
                                name="fio"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email адрес"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                InputProps={{
                                    inputComponent: EmailMask
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label="Телефон"
                                required
                                    autoComplete="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    InputProps={{
                                        inputComponent: PhoneMask
                                    }}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="subject">Наименование субъекта РФ</InputLabel>
                                <Select
                                    id={"subject"}
                                    name={"subject"}
                                    label="Наименование субъекта РФ"
                                    required
                                >
                                    {subjects.map(l => (
                                        <MenuItem key={l} value={l}>{l}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                required
                                fullWidth
                                id="name"
                                label="Полное Наименование образовательной организации (как в Уставе)"
                                name="name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="OKOPF"
                                label="ИНН"
                                name="OKOPF"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip title="Принадлежность к государственным, муниципальным или частным образовательным организациям">
                            <FormControl fullWidth>
                                <InputLabel id="prinadlegnost">Принадлежность к государственным, муниципальным или частным образовательным организациям</InputLabel>
                                <Select
                                    id="prinadlegnost"
                                    name="prinadlegnost"
                                        required
                                    fullWidth
                                    label="Принадлежность к государственным, муниципальным или частным образовательным организациям"
                                >
                                    {prinadlegnosti.map(l => (
                                        <MenuItem key={l} value={l}>{l}</MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip title="Организация / организации, выполняющая (выполняющии) функции и полномочия учредителя образовательной организации">
                                <TextField
                                    required
                                    fullWidth
                                    id="uchereditel"
                                    label="Организация / организации, выполняющая (выполняющии) функции и полномочия учредителя образовательной организации"
                                    name="uchereditel"
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label={`Настоящим даю согласие на обработку моих персональных данных УлГПУ им. И.Н. Ульянова, в том числе на совершение действий, предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 года №152-ФЗ "О персональных данных"`}
                                onChange={(e) => {
                                    setIsAccept(e.target.checked)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HCaptcha
                                sitekey="29473b54-cb7a-41f2-a3c8-4e18c0f42c8c"
                                onLoad={onLoad}
                                onVerify={setToken}
                                ref={captchaRef}
                                languageOverride="ru"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!isAccept}
                    >
                        Регистрация
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2" component={RouterLink} to={"/login"}>
                                Ранее регистрировались? Тогда Войдите в систему
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}