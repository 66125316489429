import React from "react";
import {useSortBy, useTable, useFilters, usePagination} from "react-table";
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';

import DefaultColumnFilter from "./DefaultColumnFilter";


function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [enableFilters, setEnableFilters] = React.useState(false);
    const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter,}),[])
    const {
        getTableProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        setPageSize,
        state: {pageIndex}

    } = useTable({
            columns,
            data,
            defaultColumn,
        },
        useFilters,
        useSortBy,
        usePagination
    )


    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(event.target.value)
        setRowsPerPage(+event.target.value);
    };

    // Render the UI for your table
    return (
        <>
        <TableContainer >
        <MaUTable {...getTableProps()}>
            <TableHead>
                {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <TableSortLabel
                                    active={column.isSorted}
                                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                                >
                                    {column.render('Header')}
                                </TableSortLabel>
                                {enableFilters && column.canFilter ? column.render('Filter') : null}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
                {/*
                <Tooltip title="Включить фильтры" style={{position:'absolute', top:"80px", right:"20px"}}>
                    <IconButton
                        aria-label="Включить фильтры"
                        onClick={() => {
                            setEnableFilters(!enableFilters)
                        }}
                        color={enableFilters ? "primary" : ""}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                    */}
            </TableHead>
            <TableBody>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <TableRow {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <TableCell {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </MaUTable>
        </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={pageIndex}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonText={"Предыдущая страница"}
                labelDisplayedRows={({from, to , count}) => `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`}
                labelRowsPerPage={"Записей на странице"}
            />
            </>
    )
}

export default Table;
