import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from "axios";
import {Link} from "react-router-dom";

export default function MailTemplatesList(props) {

    const [templatesList, setTemplatesList] = useState([]);

    const loadTemplates = async () => {
        try {
            let res = await axios.get(`/json/dashboard/mailTemplatesList`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setTemplatesList(res.data.templates)
            } else {
                props.showSnackbar("Ошибка загрузки шаблонов", "error");
                console.log("error")
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        loadTemplates();
    },[]);

    return (
        <>
            <Card>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    component={Link}
                    to={`/forms/addMailTemplate`}
                >
                    Добавить шаблон
                </Button>
                <CardContent>
                    {!!templatesList && templatesList.map((template, idx) => (
                        <ListItem
                            key={idx}
                            component={Link}
                            to={`/forms/mailTemplate/${template.name}`}
                        >
                            <ListItemText
                                primary={template.title}
                                secondary={template.name}
                            />
                        </ListItem>
                    ))}
                </CardContent>
            </Card>
        </>
    );
}
