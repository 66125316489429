import React, {useEffect, useState} from 'react';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from "@mui/material/Tooltip";
import Select from '@mui/material/Select';
import PersonIcon from '@mui/icons-material/Person';
import axios from "axios";
import {
     useParams,
} from "react-router-dom";

import PhoneMask from "./PhoneMask";
import EmailMask from "./EmailMask";

import {subjects} from './subjects';
import {prinadlegnosti} from './prinadlegnosti';

export default function UserInfoForm(props) {

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console

        axios.post(props.edit ? `/json/forms/saveUserInfo?id=${params.id}` :  `/json/forms/saveUserInfo`, {
            fio: data.get('fio'),
            phone: data.get('phone'),
            subject: data.get('subject'),
            name: data.get('name'),
            OKOPF: data.get('OKOPF'),
            prinadlegnost: data.get('prinadlegnost'),
            uchereditel: data.get('uchereditel'),
        }).then((res) => {
            if(res.data.status === "ok"){
                    props.showSnackbar("Сохранено", "success");
            }else{
                props.showSnackbar("Ошибка", "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        })
    };

    const [id, setId] = useState("");
    const [fio, setFio] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [OKOPF, setOKOPF] = useState("");
    const [prinadlegnost, setPrinadlegnost] = useState("");
    const [uchereditel, setUchereditel] = useState("");
    const [rnd, setRnd] = useState(Math.random());
    const [noEmail, setNoEmail] = useState(false);

    let params = useParams();

    const loadUserInfo = async () => {
        try {
            let res = await axios.get(props.edit ? `/json/users/userInfo?id=${params.id}&rnd=${rnd}` : `/json/users/myUserInfo?rnd=${rnd}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                const ui = res.data.ui;
                setId(ui._id)
                setFio(ui.fio);
                setEmail(ui.username);
                setPhone(ui.phone);
                setSubject(ui.subject);
                setName(ui.name);
                setOKOPF(ui.OKOPF);
                setPrinadlegnost(ui.prinadlegnost);
                setUchereditel(ui.uchereditel);
                setNoEmail(ui.noemail);
            } else {
                props.showSnackbar("Ошибка загрузки", "error");
                console.log("error")
            }
        }catch (e) {
            props.showSnackbar("Ошибка загрузки", "error");
            console.log(e)
        }
    }

    useEffect(() => {
            loadUserInfo();
            if(!!props.unsubscribe){
                toogleNoEmail();
                setNoEmail(true);
            }
    },[]);

    const toogleNoEmail = async() => {
        try {
            let res = await axios.get(`/json/users/toogleNoEmail`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Информация о подписке изменена", "success");
            } else {
                props.showSnackbar("Ошибка изменения подписки", "error");
            }
        }catch (e) {
            props.showSnackbar("Ошибка изменения подписки", "error");
            console.log(e)
        }
    }


    return (
        <>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <PersonIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Информация о пользователе
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="fio"
                            label="ФИО контактного лица"
                            name="fio"
                            value={fio}
                            onChange={(e) => setFio(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email адрес"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                                inputComponent: EmailMask
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Телефон"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            InputProps={{
                                inputComponent: PhoneMask
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="subject">Наименование субъекта РФ</InputLabel>
                            <Select
                                    id={"subject"}
                                    name={"subject"}
                                    label="Наименование субъекта РФ"
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                                value={subject}
                            >
                                {subjects.map(l => (
                                    <MenuItem key={l} value={l}>{l}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            value={name}
                            id="name"
                            label="Полное Наименование образовательной организации (как в Уставе)"
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            fullWidth
                            id="OKOPF"
                            label="ИНН"
                            name="OKOPF"
                            value={OKOPF}
                            onChange={(e) => setOKOPF(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title="Принадлежность к государственным, муниципальным или частным образовательным организациям">
                            <FormControl fullWidth>
                                <InputLabel id="prinadlegnost">Принадлежность к государственным, муниципальным или частным образовательным организациям</InputLabel>
                                <Select
                                    id="prinadlegnost"
                                    name="prinadlegnost"
                                    value={prinadlegnost}
                                    required
                                    fullWidth
                                    label="Принадлежность к государственным, муниципальным или частным образовательным организациям"
                                    onChange={(e) => {
                                        setPrinadlegnost(e.target.value);
                                    }}
                                >
                                    {prinadlegnosti.map(l => (
                                        <MenuItem key={l} value={l}>{l}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            label="Организация / организации, выполняющая (выполняющии) функции и полномочия учредителя образовательной организации"
                            id="uchereditel"
                            name="uchereditel"
                            value={uchereditel}
                            onChange={(e) => setUchereditel(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel control={<Checkbox
                            checked={noEmail}
                            onChange={(event) => {
                                setNoEmail(event.target.checked);
                                toogleNoEmail();
                            }}
                        />}
                            label="Отказаться от рассылок по Email" />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Сохранить
                </Button>
            </Box>
        </>
    );
}
