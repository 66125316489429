import React, {useEffect, useState} from "react";
import Table from "./Table";
import IconButton from "@mui/material/IconButton";
import IconThumbUp from '@mui/icons-material/ThumbUp';
import IconThumbDown from '@mui/icons-material/ThumbDown';
import {
    Link
} from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from "axios";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Moderation(props) {
    useEffect(() => {
        getList();
    },[]);


    const [openDel, setOpenDel] = useState(false);

    const handleCloseDel = () => setOpenDel(false);
    const handleOpenDel = (dt) => {
        setUserInfo({
            fio: dt.fio,
            username: dt.username,
            phone: dt.phone,
            name: dt.name,
        });
        setOpenDel(true);
    }

    const [userInfo, setUserInfo] = useState({fio: "", username: "", phone: "", name: ""});

    const getList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/userList?type=registered&rnd=${Math.random()}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setModerationList(res.data.rows);
                return res.data.rows;
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса списка ", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const allowUser = async (username) => {
        try {
            let res = await axios.get(`/json/dashboard/giveAccess?username=${username}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Доступ предоставлен", "success");
                getList();
            } else {
                console.log("error")
                props.showSnackbar("Ошибка предоставления доступа", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const declineUser = async (username) => {
        try {
            let res = await axios.get(`/json/dashboard/declineAccess?username=${username}`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Доступ отклонён", "success");
                getList();
            } else {
                console.log("error")
                props.showSnackbar("Ошибка предоставления доступа", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }


    const columns = React.useMemo(
        () => [
            {
                Header: 'ФИО',
                accessor: 'fio'
            },
            {
                Header: 'Email',
                accessor: 'username'
            },
            {
                Header: 'Телефон',
                accessor: 'phone'
            },
            {
                Header: 'Наименование субъекта РФ',
                accessor: 'subject',
            },
            {
                Header: 'Наименование образовательной организации',
                accessor: 'name',
            },
            {
                Header: 'Код ОКОПФ',
                accessor: 'OKOPF',
            },
            {
                Header: 'Принадлежность к государственный, муниципальным или частным образовательным организациям',
                accessor: 'prinadlegnost',
            },
            {
                Header: 'Организация / организации, выполняющая (выполняющии) функции и полномочия учредителя образовательной организации',
                accessor: 'uchereditel',
            },
            {
                Header: 'Управление',
                accessor: 'uid',
                disableFilters: true,
                Cell: ({cell}) => (
                    <>
                        <IconButton aria-label={"Одобрить"} color={"primary"} onClick={()=>{allowUser(cell.row.values.username);}}  >
                            <IconThumbUp />
                        </IconButton>
                        <IconButton aria-label={"Отклонить"} color={"error"} onClick={()=>{handleOpenDel(cell.row.original)}}  >
                            <IconThumbDown />
                        </IconButton>
                    </>
                )
            },
        ],
        []
    )

    const [moderationList, setModerationList] = useState([]);
    const data = React.useMemo(() => moderationList, [moderationList])

    return (
        <>
            <Table columns={columns} data={data} />
            <Modal
                open={openDel}
                onClose={handleCloseDel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title-del" variant="h6" component="h2">
                        Внимание! Нажав на кнопку ниже Вы удалите заявку и все данные с ней связанные.
                    </Typography>
                    <Typography id="modal-modal-description-del" sx={{ mt: 2 }}>
                        {userInfo.name}
                    </Typography>
                    <Button color="error" onClick={ () => {declineUser(userInfo.username);}} >Удалить заявку</Button>
                </Box>
            </Modal>
        </>
    )
}
