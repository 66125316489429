import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import {
    Link as RouterLink,
    useNavigate, useParams
} from "react-router-dom";
import axios from "axios";
import {
    useEffect,
    useState,
} from "react";


export default function NewPassword(props) {
    let navigate = useNavigate();

    let params = useParams();

    const [sent, setSent] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        try{
            let res = await axios.post(`/json/users/setNewPassword`, {
                password: data.get('password'),
                token: params.token
            });
            setSent(true);
            if(!!res.data.status && res.data.status === "ok"){
                props.showSnackbar("Пароль изменён", "success");
                navigate("/login");
            }else{
                props.showSnackbar("Ссылка для сброса пароля не действительна", "error");
            }
        }catch(err){
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        }

    };


    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <VpnKeyIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Задать новый пароль
                </Typography>
                {
                    sent ?
                        <Typography variant="subtitle1" component={"div"}>
                            Новый пароль установлен. Вы будете перенаправленны на форму авторизации.
                        </Typography>
                        :
                        <>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Пароль"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Отправить
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2" component={RouterLink} to={"/login"}>
                                            Войти
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2" component={RouterLink} to={"/signup"}>
                                            {"Нет аккаунта? Зарегистрируйтесь"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                }
            </Box>
        </Container>
    );
}
