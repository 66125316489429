import React from "react";
import {IMaskInput} from "react-imask";

export default React.forwardRef(function EmailMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={/^\S*@?\S*$/}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
