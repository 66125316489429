import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import {
    Link as RouterLink,
    useNavigate
} from "react-router-dom";
import axios from "axios";
import {
    useEffect,
    useState,
    useRef
} from "react";


export default function RequestPasswordReset(props) {
    let navigate = useNavigate();

    const [sent, setSent] = useState(false);
    const [token, setToken] = useState(null);
    const captchaRef = useRef(null);

    const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
        captchaRef.current.execute();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        try{
            let res = await axios.post(`/json/users/requestPasswordReset`, {
                username: data.get('email'),
                token: token
            });
            if(!!res.data.status && res.data.status === "ok"){
                setSent(true);
                props.showSnackbar("Письмо отправлено. Проверьте Ваш Email", "success");
            }else{
                props.showSnackbar("Пользователь с таким Email не зарегистрирован", "error");
            }
        }catch(err){
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        }

    };

    useEffect(() => {
        if (token)
            console.log(`hCaptcha Token: ${token}`);
    },[token]);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <VpnKeyIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Запросить сброс пароля
                </Typography>
                {
                    sent ?
                        <Typography variant="subtitle1" component={"div"}>
                            Письмо отправлено. Проверьте Ваш Email.
                        </Typography>
                        :
                        <>
                            <Typography variant="subtitle1" component={"div"}>
                                Вам будет отправлено письмо с ссылкой для сброса пароля
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email адрес"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                            <HCaptcha
                                sitekey="29473b54-cb7a-41f2-a3c8-4e18c0f42c8c"
                                onLoad={onLoad}
                                onVerify={setToken}
                                ref={captchaRef}
                                languageOverride="ru"
                            />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Отправить
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2" component={RouterLink} to={"/login"}>
                                            Войти
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="#" variant="body2" component={RouterLink} to={"/signup"}>
                                            {"Нет аккаунта? Зарегистрируйтесь"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                }
            </Box>
        </Container>
    );
}
