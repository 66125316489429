// Define a default UI for filtering
import React from "react";
import TextField from "@mui/material/TextField";

function DefaultColumnFilter({
                                 column: { filterValue, preFilteredRows, setFilter },
                             }) {
    const count = preFilteredRows.length

    return (
        <TextField
            size="small"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            label={`Поиск ${count} записей...`}
        />
    )
}

export default DefaultColumnFilter